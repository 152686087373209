import { firestore } from "@/plugins/firebase"
import {
    collection,
    query,
    where,
    getDocs,
    onSnapshot,
    getDoc,
    doc
} from "firebase/firestore"
import axios from "axios"
import Vue from "vue"
/* import Vue from "vue"
 */
async function translateProductnamen(productname) {
    const energietyp={
        "G0": "Gas",
        "DE": "Strom"
    }
    //const produkttyp={
    //    "B": "Base",
    //    "P": "Peak"
    //}
    const produktzeitraum={
        "Y01": "Jahresprodukt",
        "Q01": "Quartalsprodukt (erstes Quartal)",
        "Q04": "Quartalsprodukt (zweites Quartal)",
        "Q07": "Quartalsprodukt (drittes Quartal)",
        "Q10": "Quartalsprodukt (viertes Quartal)",
        "M01": "Monatsprodukt (Januar)",
        "M02": "Monatsprodukt (Februar)",
        "M03": "Monatsprodukt (März)",
        "M04": "Monatsprodukt (April)",
        "M05": "Monatsprodukt (Mai)",
        "M06": "Monatsprodukt (Juni)",
        "M07": "Monatsprodukt (Juli)",
        "M08": "Monatsprodukt (August)",
        "M09": "Monatsprodukt (September)",
        "M10": "Monatsprodukt (Oktober)",
        "M11": "Monatsprodukt (November)",
        "M12": "Monatsprodukt (Dezember)"
    }

    // DEBY012022
    if (productname == null || productname == "null" || productname.length !== 10) {
        return "Ungültiger Produktname"
    }
    const re = /^(DE|G0)(B|P)((Y|Q|M)\d{2})(\d{4})$/;
    let regextest = re.test(productname)
    if (!regextest) { return "Fehler im Test" }
    let m = productname.match(re);
    //const output=`${energietyp[m[1]]} ${produkttyp[m[2]]} ${produktzeitraum[m[3]]} ${m[5]}`
    const output=`${energietyp[m[1]]} ${produktzeitraum[m[3]]} ${m[5]}`
    return output
}

async function getPrice(product, debug = false) {
    var data = new FormData()
    data.append("apikey", process.env.VUE_APP_FIREBASE_APIKEY)
    data.append("product", product)
    if (debug) {
        data.append("debug", "true")
    }

    var config = {
        method: "post",
        url: process.env.VUE_APP_FIREBASE_HOSTING + "/queryPrice",
        data: data
    }

    let response = await axios(config)
    return response.data
}

function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
}

export default {
    namespaced: true,
    state: {
        isLoading: false,
        alleVertraege: {},
        aktuelleVertraege: {},
        aktuelleVertragsnamen: [],
        aktuellerVertrag: {},
        aktuellerVertragsname: "",
        aktuelleTranchenzeitraeume: [],
        aktuellerTranchenzeitraum: "",
        aktuelleTranche: {
            cost: 0,
            Preise: {
                base: { price: 0 },
                peak: { price: 0 }
            },
            Strukturkonstante: 0
        },
        aktuellerStoppLoss: [],
        geschlosseneTranchen: {},
        geschlosseneTranchenNamen: [],
        naechsterOffenerTranchenName: "",
        TrachenSummen: {
            volumeAllTranches: 0,
            volume: 0,
            cost: 0,
            price: 0,
            numberClosed: 0
        },
        abgeschlossenerVertragsart: null
    },
    mutations: {
        set_alleVertraege(state, payload) {
            state.alleVertraege = payload
        },
        set_aktuelleVertraege(state, payload) {
            if(payload){
                Object.entries(payload).forEach(([key, value]) => {
                    Vue.set(state.aktuelleVertraege, key, value)
                })
                state.aktuelleVertragsnamen = Object.keys(payload)
                state.aktuellerVertragsname =  Object.keys(payload)[0]
            }else{
                state.aktuelleVertraege = {}
                state.aktuelleVertragsnamen = []
                state.aktuellerVertragsname =  ""
            }
        },
        set_aktuellerVertrag(state, payload) {
            // state.aktuellerVertrag = payload
            payload ? Object.entries(payload).forEach(([key, value]) => {
                Vue.set(state.aktuellerVertrag, key, value)
            }) : state.aktuellerVertrag = {}
        },
        set_aktuelleTranche(state, payload) {
            /* Vue.set(state.aktuelleTranche, payload) */
            state.aktuelleTranche = payload
        },
        set_aktuelleTranchenzeitraeume(state, payload) {
            state.aktuelleTranchenzeitraeume = payload
        },
        set_aktuellerTranchenzeitraum(state, payload) {
            state.aktuellerTranchenzeitraum = payload
        },
        set_TrachenSummen(state, payload) {
            state.TrachenSummen = payload
        },
        set_GeschlosseneTranchen(state, payload) {
            state.geschlosseneTranchen = payload.geschlosseneTranchen
            state.geschlosseneTranchenNamen = payload.geschlosseneTranchenNamen
        },
        set_NaechsterOffenerTranchenName(state, payload) {
            state.naechsterOffenerTranchenName = payload
        },
        set_abgeschlossenerVertragsart(state, payload) {
            state.abgeschlossenerVertragsart = payload
        }
    },
    actions: {
        async holeStammdaten({ state, commit }, { firmen }) {
            state.isLoading = true;
            const firmenids = firmen.map((f) => {
                //return parseInt(f.id)
                return f.id
            })
            const tranchenRef = collection(firestore, "Trancheninfos")
            const collRef = collection(firestore, "Stammdaten")
            const q = query(
                collRef,
                where("geschaeftspartnerNr", "in", firmenids)
            )
            const querySnapshot = await getDocs(q)
            const alleVertraege = {}  //new
            for (const doc of querySnapshot.docs) {
                const docdata = { ...{ id: doc.id }, ...doc.data() }
                //state.alleVertraege[docdata.geschaeftspartnerNr][docdata.vertragsnummer]["marktlokation"]=[];
                //state.alleVertraege[docdata.geschaeftspartnerNr][docdata.vertragsnummer].anzahlMarktlokationen=0;
                if (!alleVertraege[docdata.geschaeftspartnerNr]) {
                    alleVertraege[docdata.geschaeftspartnerNr] = {}
                }
                if (!alleVertraege[docdata.geschaeftspartnerNr][docdata.vertragsnummer]) {
                    const tranchenq = query(
                        tranchenRef,
                        where("Vertragsnummer", "==", docdata.vertragsnummer)
                    )
                    const tranchenSnapshot = await getDocs(tranchenq)
                    let tranchen = {}
                    for (const tdoc of tranchenSnapshot.docs) {
                        tranchen[tdoc.id] = tdoc.data()
                    }
                    alleVertraege[docdata.geschaeftspartnerNr][docdata.vertragsnummer] = {
                        vertragsbeginn: docdata.vertragsbeginn,
                        vertragsende: docdata.vertragsende,
                        vertragsart: docdata.vertragsart,
                        commodity: docdata.commodity,
                        vertragsnehmer: docdata.vertragsnehmer,
                        geschaeftspartnerNr: docdata.geschaeftspartnerNr,
                        anzahlMarktlokationen: 0,
                        marktlokation: [],
                        tranchen: tranchen
                    }
                }
                alleVertraege[docdata.geschaeftspartnerNr][docdata.vertragsnummer]["marktlokation"].push(docdata.marktlokation)
                alleVertraege[docdata.geschaeftspartnerNr][docdata.vertragsnummer].anzahlMarktlokationen++
            }
            commit ("set_alleVertraege", alleVertraege)
            state.isLoading = false;
        },
        async holeVertragsdaten({ state, commit }, { firma_id }) {
            state.aktuelleTranche = {
                cost: 0,
                Preise: {
                    base: { price: 0 },
                    peak: { price: 0 }
                },
                Strukturkonstante: 0
            },
            state.aktuellerStoppLoss = [],

            // console.log("holeVertragsdaten--------------", firma_id, state.alleVertraege[firma_id])
            // state.aktuelleVertraege = Object.assign(
            //     {},
            //     state.alleVertraege[firma_id]
            // )
            commit("set_aktuelleVertraege", state.alleVertraege[firma_id]? state.alleVertraege[firma_id]: {})
            
            // state.aktuelleVertragsnamen = Object.keys(state.alleVertraege[firma_id])
            // state.aktuellerVertragsname =  Object.keys(state.alleVertraege[firma_id])[0]
            // state.aktuellerVertrag = Object.assign(
            //     {},
            //     state.aktuelleVertraege[state.aktuelleVertragsnamen[0]]
            // )
            commit("set_aktuellerVertrag", state.aktuelleVertraege[state.aktuelleVertragsnamen[0]])

            // state.aktuelleTranchenzeitraeume = Object.keys(
            //     state.aktuellerVertrag["tranchen"]
            // )
            commit("set_aktuelleTranchenzeitraeume", Object.keys(state.aktuellerVertrag).length ? Object.keys(state.aktuellerVertrag["tranchen"]): [])

            // state.aktuellerTranchenzeitraum =
            //     state.aktuelleTranchenzeitraeume[0]
            commit("set_aktuellerTranchenzeitraum", state.aktuelleTranchenzeitraeume[0])

        },
        async setAktuellerVertrag({ state, commit }, { vertrag }) {
            const aktuellerVertrag = state.aktuelleVertraege[vertrag]
            commit("set_aktuellerVertrag", aktuellerVertrag)

            const aktuelleTranchenzeitraeume = Object.keys(
                state.aktuellerVertrag["tranchen"]
            )
            commit("set_aktuelleTranchenzeitraeume", aktuelleTranchenzeitraeume)

            const aktuellerTranchenzeitraum = state.aktuelleTranchenzeitraeume[0]
            commit("set_aktuellerTranchenzeitraum", aktuellerTranchenzeitraum)

            // const currentContractType = state.aktuellerVertrag.vertragsart
            // console.log("🚀 ~ setAktuellerVertrag ~ vertrag:", vertrag, currentContractType, state)

        },
        async setAktuelleTranche({ state, commit }, { tranchenzeitraum }) {            
            const payload = state.aktuellerVertrag["tranchen"][tranchenzeitraum]
            payload["Preise"] = {
                base: { price: 0 },
                peak: { price: 0 }
            }
            payload["Endpreis"] = {
                price: 0
            }
            payload["Strukturkonstante"] = 0
            payload["cost"] = 0
            payload["Preise"]["base"] = await getPrice(
                payload.Produkte.base
            )
            payload["Produkte"]["basetext"] = await translateProductnamen(payload.Produkte.base)
            if (payload.Produkte.peak) {
                payload["Preise"]["peak"] = await getPrice(
                    payload.Produkte.peak
                )
                payload["Produkte"]["peaktext"] = await translateProductnamen(payload.Produkte.peak)
            } else {
                payload["Preise"]["peak"] = { price: "0" }
            }

            let StrukturkonstanteinEuro = payload["StrukturkonstanteinEuro"]
            let TrancheMengeinkWh = payload["TrancheMengeinkWh"]
            if (typeof (StrukturkonstanteinEuro) == "string") {
                StrukturkonstanteinEuro = StrukturkonstanteinEuro.replace(".", "")
                StrukturkonstanteinEuro = StrukturkonstanteinEuro.replace(",", ".")
            }
            if (typeof (TrancheMengeinkWh) == "string") {
                TrancheMengeinkWh = TrancheMengeinkWh.replace(".", "")
                TrancheMengeinkWh = TrancheMengeinkWh.replace(",", ".")
            }

            payload["Strukturkonstante"] = parseFloat(
                parseFloat(
                    (
                        (parseFloat(StrukturkonstanteinEuro) / parseInt(TrancheMengeinkWh)) * 1000
                    ).toFixed(3)
                )
            )
            commit("set_aktuelleTranche",payload)

        },
        async getAktuelleStoppLossDaten({ state, commit }, { vertrag }) {
            state.aktuellerStoppLoss = [];
            const coll = `/Komfortbewirtschaftung/Stammdaten/Vertraege/${vertrag}`
            const collRef = collection(firestore, coll+"/zeitbereiche")
            const querySnapshot = await getDocs(collRef)

            const DocRef = doc(firestore, coll)
            const vertragsartData = (await getDoc(DocRef)).data()
            commit("set_abgeschlossenerVertragsart", vertragsartData.abgeschlossen)

            const tranchenRef = collection(firestore, coll+"/tranchen")
            const queryTranchenSnapshot = await getDocs(tranchenRef)

            let StoppLossData = []
            for (const doc of querySnapshot.docs) {
                const docdata = doc.data()
                const now = new Date().getTime()/1000
                let slotStatus = "offen"

                const timeStamps = Object.keys(docdata.bereiche)
                const lowerTimeSlot = timeStamps.filter(timeStamp => timeStamp <= now)
                const currentTimeSlot = lowerTimeSlot.length > 0 ? Math.max(...lowerTimeSlot) : null
                const slotNames = docdata.bereiche[currentTimeSlot] && docdata.bereiche[currentTimeSlot].namen
                const slot = slotNames && queryTranchenSnapshot.docs.find(doc => doc.id == slotNames[slotNames.length-1]).data()
                slotStatus = slot && slot.status

                StoppLossData.push({
                    "name": doc.id,
                    "stoppLoss": docdata.delta,
                    "limit": typeof(docdata.value) == "string" ? parseFloat(docdata.value) : docdata.value,
                    "status": slotStatus
                })
            }
            StoppLossData.sort((a,b) => a.limit - b.limit)
            state.aktuellerStoppLoss = StoppLossData
        },
        async calculatePrice({ state, commit }) {
            let factorBase = state.aktuelleTranche.FaktorBase
            let factorPeak = state.aktuelleTranche.FaktorPeak
            factorBase = factorBase&&factorBase.replace(".", "")
            factorBase = factorBase&&factorBase.replace(",", ".")
            if (typeof factorPeak == "string") {
                factorPeak = factorPeak.replace(".", "")
                factorPeak = factorPeak.replace(",", ".")
            }
            // console.log("calculatePrice",parseFloat(factorPeak), parseFloat(state.aktuelleTranche.Preise.peak.price) )
            const aktuelleTranche = state.aktuelleTranche
            aktuelleTranche["Endpreis"] = {
                base:
                    parseFloat(factorBase) *
                    parseFloat(aktuelleTranche.Preise.base.price),
                peak:
                    parseFloat(factorPeak) *
                    parseFloat(aktuelleTranche.Preise.peak.price),
                struct: aktuelleTranche.Strukturkonstante
            }
            aktuelleTranche["Endpreis"]["price"] = roundToTwo(
                aktuelleTranche["Endpreis"]["base"] +
                aktuelleTranche["Endpreis"]["peak"] +
                aktuelleTranche["Endpreis"]["struct"]);
            let TrancheMengeinkWh = aktuelleTranche["TrancheMengeinkWh"]
            TrancheMengeinkWh = TrancheMengeinkWh&&TrancheMengeinkWh.replace(".", "")
            TrancheMengeinkWh = TrancheMengeinkWh&&TrancheMengeinkWh.replace(",", ".")
            //const TrancheMengeinMWh = parseFloat(TrancheMengeinkWh) / 1000
            const TrancheMengeinMWh = parseInt(TrancheMengeinkWh) / 1000
            aktuelleTranche["cost"] =
                TrancheMengeinMWh * aktuelleTranche["Endpreis"]["price"];
            commit("set_aktuelleTranche", aktuelleTranche)
        },
        async getOrderedTranches({ state, commit }, { tranchenzeitraum }) {
            const datapath = `/Tranchenorders/${tranchenzeitraum}/orders`
            const tranchenRef = collection(firestore, datapath)
            const tranchenq = query(tranchenRef)

            onSnapshot(tranchenq, (querySnapshot) => {
                let tranchen = {}
                // TrachenSummen berechnen
                let countVolumen = 0
                let countCost = 0
                let countPriceAddon = 0
                let countPrice = 0
                let CountNumberClosed = 0
                querySnapshot.forEach((doc) => {
                    const tranche = doc.data()
                    tranche.pricing.price = tranche.pricing.price * 10 // Preisumrechnung ct/kWh zu €/MWh
                    CountNumberClosed++
                    countVolumen = countVolumen + tranche.volume
                    countCost = countCost + tranche.cost
                    countPriceAddon =
                        countPriceAddon + tranche.pricing.price
                    countPrice = countPriceAddon / CountNumberClosed
                    tranchen[doc.id.replace(" ", "_")] = tranche
                })
                const trachenSummen = {
                    volume: countVolumen.toLocaleString("de-DE", {
                        maximumFractionDigits: 2
                    }),
                    cost: countCost.toLocaleString("de-DE", {
                        maximumFractionDigits: 2
                    }),
                    price: countPrice.toLocaleString("de-DE", {
                        maximumFractionDigits: 2
                    }),
                    numberClosed: CountNumberClosed,
                    numberAllTranches: state.aktuelleTranche.Tranchen.length,
                    volumeAllTranches: (parseInt(state.aktuelleTranche.TrancheMengeinkWh) * state.aktuelleTranche.Tranchen.length).toLocaleString("de-DE", {
                        maximumFractionDigits: 2
                    })
                }
                commit("set_TrachenSummen", trachenSummen)

                const geschlosseneTranchenPayload = {
                    geschlosseneTranchen : tranchen,
                    geschlosseneTranchenNamen : Object.keys(tranchen)
                }
                commit("set_GeschlosseneTranchen", geschlosseneTranchenPayload)

                const counter = Object.keys(tranchen).length
                const naechsterOffenerTranchenName =
                    state.aktuelleTranche.Tranchen[counter]
                commit("set_NaechsterOffenerTranchenName", naechsterOffenerTranchenName)


            })
        }
    }
}
