import { firestore } from "@/plugins/firebase"
import {
    doc,
    updateDoc,
    deleteDoc,
    collection,
    query,
    onSnapshot,
    getDoc
} from "firebase/firestore"
import { arrayUnique } from "@/helpers/arrayHelpers"

export default {
    state: {
        allUserByID: {},
        allUserByName: {},
        allUserArray: [],
        allUserNameToID: {},
        allUserIDToName: {},
        allCompany: {},
        allInvitations: {},
        allInvitationsArray: [],

    },
    mutations: {},
    actions: {
        async getAllUsers({ state }) {
            const datapath = "/userprofiles"
            const vOrderRef = collection(firestore, datapath)
            const vOrderq = query(vOrderRef)

            onSnapshot(vOrderq, (querySnapshot) => {
                state.allUserArray = [];
                state.allCompanyArray = [];
                querySnapshot.forEach((doc) => {
                    const docdata = { ...{ id: doc.id }, ...doc.data() }
                    state.allUserByID[doc.id] = docdata
                    state.allUserArray.push(docdata)
                    state.allUserByName[`${docdata.name} ${docdata.family_name}`] = docdata
                    state.allUserNameToID[`${docdata.name} ${docdata.family_name}`] = doc.id
                    state.allUserIDToName[doc.id] = `${docdata.name} ${docdata.family_name}`
                    if ("firmen" in docdata) {
                        for (const firmenid of docdata.firmen) {
                            if (state.allCompany[firmenid] == undefined) {
                                state.allCompany[firmenid] = []
                            }
                            state.allCompany[firmenid].push(doc.id)
                        }
                    }
                })
            })
        },
        async getAllUsersFromCompanies({ state },{companies}) {
            const datapath = "/userprofiles"
            const vOrderRef = collection(firestore, datapath)
            const vOrderq = query(vOrderRef)
            console.log(companies)
            onSnapshot(vOrderq, (querySnapshot) => {
                state.allUserArray = [];
                state.allCompanyArray = [];
                querySnapshot.forEach((doc) => {
                    const docdata = { ...{ id: doc.id }, ...doc.data() }
                    state.allUserByID[doc.id] = docdata
                    state.allUserArray.push(docdata)
                    state.allUserByName[`${docdata.name} ${docdata.family_name}`] = docdata
                    state.allUserNameToID[`${docdata.name} ${docdata.family_name}`] = doc.id
                    state.allUserIDToName[doc.id] = `${docdata.name} ${docdata.family_name}`
                    if ("firmen" in docdata) {
                        for (const firmenid of docdata.firmen) {
                            if (state.allCompany[firmenid] == undefined) {
                                state.allCompany[firmenid] = []
                            }
                            state.allCompany[firmenid].push(doc.id)
                        }
                    }
                })
            })
        },
        async deleteUser({ state }, { id }) {
            console.log("DeleteUser", state, id)
            const userdoc = doc(firestore, "userprofiles", id)
            await deleteDoc(userdoc);
            const permissionsdoc = doc(firestore, "permissions", id)
            await deleteDoc(permissionsdoc);
        },
        async deleteInvitation({ state }, { id }) {
            console.log("DeleteInvitation", state, id)
            const userdoc = doc(firestore, "Einladungen", id)            
            await deleteDoc(userdoc);
        },
        async deleteUserFromCompany({ state }, { id, companyid }) {
            console.log("DeleteUserFromCompany", state, id, companyid)
            const userref = doc(firestore, "userprofiles", id)
            const userdoc = await getDoc(userref)
            if (userdoc.exists()) {
                const companiesArray = userdoc.data().firmen || [];
                const companyToRemove = companyid;
                const updatedCompaniesArray = companiesArray.filter(company => company !== companyToRemove);

                // Aktualisiere das aktualisierte Array in der Firebase-Datenbank für den Benutzer
                await updateDoc(userref, {
                    firmen: updatedCompaniesArray,
                });
            }
        },
        async addUserToFirmen({ state }, { ids, companyids }) {
            console.log("addUserToFirmen", state, ids, companyids)
            for (const id of ids) {
                const userref = doc(firestore, "userprofiles", id)
                const userdoc = await getDoc(userref)
                if (userdoc.exists()) {
                    const companiesArray = userdoc.data().firmen || [];
                    let mergedCompanies = arrayUnique(companiesArray.concat(companyids));
                    await updateDoc(userref, {
                        firmen: mergedCompanies,
                    });
                }
            }

        }
    },

}

